.how-it-works-container {
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 7rem;  
  background-color: #DED7ED; /* Light purple background */
  font-family: 'Host Grotesk', sans-serif;
}

.how-it-works-title {
  font-size: 2.5rem;
  color: #4b2e83; /* Dark purple */
  margin-bottom: 2rem;
  font-weight: 700;
}

.tabs-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.tab {
  padding: 0.75rem 1.5rem;
  border-radius: 1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  width: 12rem;
}

.tab.active {
  background-color: #4b2e83; /* Dark purple */
  color: white;
  border: 2px solid #4b2e83;
}

.tab:not(.active) {
  background-color: transparent;
  color: #4b2e83;
  border: 2px solid #4b2e83;
}

/* Fixed container to prevent overlapping */
.steps-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default 2 cards per row */
  gap: 2rem;
  width: 100%;
  box-sizing: border-box;
}

/* Fixed card sizing to prevent overlapping */
.step-card {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  box-sizing: border-box; /* Include padding in width calculation */
  min-height: 280px;
  width: 100%; /* Important to prevent overflow */
  margin: 0; /* Remove any margins that could cause overflow */
  overflow: hidden; /* Prevent content overflow */
}

.step-card:hover {
  transform: translateY(-5px);
}

.icon-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.step-icon {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}

.step-title {
  color: #4b2e83; /* Dark purple */
  font-size: 1.8rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.step-description {
  color: #333;
  font-size: 1.1rem;
  line-height: 1.5;
}

/* 4 cards per row ONLY on very large screens */
@media (min-width: 1400px) {
  .steps-container {
    grid-template-columns: repeat(4, 1fr); /* 4 cards per row */
  }
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .how-it-works-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .steps-container {
    grid-template-columns: 1fr; /* 1 card per row */
  }
  
  .step-card {
    min-height: 220px;
  }
  
  .how-it-works-title {
    font-size: 2rem;
  }
  
  .tab {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    width: auto;
  }
}