.faq-container {
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 5rem;
  background-color: #F8F4F2;
  font-family: 'Host Grotesk', sans-serif;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.faq-content {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
}

.faq-title {
  font-size: 3rem;
  font-weight: 600;
  color: #27195B;
  margin-bottom: 2rem;
}

.faq-list {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}

.faq-item {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(88, 48, 149, 0.2);
  padding-bottom: 1rem;
}

.faq-question {
  font-size: 1.8rem;
  font-weight: 500;
  color: #583095;
  margin-bottom: 0.75rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.faq-question:hover {
  color: #27195B;
}

.faq-question.active {
  color: #583095;
}

.faq-icon {
  font-size: 1.8rem;
  font-weight: 600;
  color: #583095;
}

.faq-answer {
  font-size: 1.25rem;
  color: #27195B;
  line-height: 1.5;
  max-width: 90%;
  font-weight: 500;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  margin-top: 0;
}

.faq-answer.active {
  max-height: 500px;
  opacity: 1;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.faq-illustration-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.faq-illustration {
  width: 100%;
  max-width: 550px;
  height: auto;
  position: relative;
  z-index: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faq-container {
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .faq-content {
    max-width: 100%;
    margin-bottom: 2rem;
  }
  
  .faq-illustration-container {
    justify-content: center;
  }

  .faq-list {
    padding-left: 0.5rem;
  }
  
  .faq-question {
    font-size: 1.5rem;
  }
  
  .faq-answer {
    font-size: 1.1rem;
  }
}