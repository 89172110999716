.operating-container {
  background-color: #DED7ED;
  padding: 3rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.operating-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 800px;
}

.location-icon-container {
  margin-bottom: 1rem;
}

.location-icon {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}

.operating-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #583095;
  margin: 0.5rem 0;
  font-family: 'Host Grotesk', sans-serif;
}

.operating-subtitle {
  font-size: 1.2rem;
  color: #27195B;
  margin-top: 0.5rem;
  font-family: 'Host Grotesk', sans-serif;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .operating-title {
    font-size: 2rem;
  }

  .operating-subtitle {
    font-size: 1.1rem;
  }

  .location-icon {
    width: 3.5rem;
    height: 3.5rem;
  }
}

@media (max-width: 480px) {
  .operating-container {
    padding: 2rem 1rem;
  }

  .operating-title {
    font-size: 1.8rem;
  }

  .operating-subtitle {
    font-size: 1rem;
  }

  .location-icon {
    width: 3rem;
    height: 3rem;
  }
}