.footer {
  background-color: #583095;
  color: white;
  padding: 2rem 3rem;
  font-family: 'Host Grotesk', sans-serif;
  overflow: hidden;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Left section styles */
.footer-left {
  flex: 1;
  min-width: 300px;
  position: relative;
  margin-right: 2rem;
}

/* Right section styles */
.footer-right {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Logo styles */
.footer-logo-container {
  margin-bottom: 2rem;
}

.footer-logo-image {
  height: 5rem;
  width: auto;
}

/* Contact section */
.footer-contact {
  margin-bottom: 2rem;
}

.footer-contact-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.footer-contact-info {
  display: flex;
  flex-direction: column;
}

.footer-contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.footer-contact-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Download section */
.download-section {
  margin-top: 2rem;
}

.download-text {
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  position: relative;
  display: inline-block;
}

.download-text::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #FF6F61;
}

.footer-app-stores {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.footer-app-store {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  cursor: pointer;
}

.footer-app-store:last-child {
  margin-right: 0;
}

.footer-apple-icon, .footer-google-play-icon {
  margin-right: 8px;
  width: 28px;
  height: 28px;
}

.footer-store-name {
  font-size: 1.05rem;
  font-weight: 400;
}

/* Links section */
.footer-links-section {
  display: flex;
  margin-bottom: 2rem;
}

.footer-links-column {
  margin-right: 3rem;
}

.footer-column-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.footer-link-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-link-list li {
  margin-bottom: 0.8rem;
}

.footer-link-list a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: opacity 0.2s ease;
}

.footer-link-list a:hover {
  opacity: 0.8;
}

/* Legal and social section */
.footer-legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
}

.footer-legal-link {
  color: white;
  text-decoration: underline;
  font-size: 1rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.footer-social {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.footer-social-icon {
  width: 36px;
  height: 36px;
  margin-left: 1rem;
  background-color: #FF6F61;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.footer-social-icon:first-child {
  margin-left: 0;
}

.footer-social-icon:hover {
  background-color: #ff8578;
}

.footer-social-icon img {
  width: 20px;
  height: 20px;
}

/* Footer bottom section */
.footer-bottom {
  margin-top: auto;
}

.registered-office-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 1rem 0;
}

.registered-office-text {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.4;
  margin-bottom: 1rem;
}

.copyright-text {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.9);
}

/* Responsive styles */
@media (max-width: 992px) {
  .footer {
    padding: 2rem;
  }
  
  .footer-left, .footer-right {
    flex: 100%;
    margin-right: 0;
  }
  
  .footer-right {
    margin-top: 3rem;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 1.5rem;
  }
  
  .footer-legal {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-social {
    margin-top: 1.5rem;
  }
  
  .footer-links-section {
    flex-direction: column;
  }
  
  .footer-links-column {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  .footer-app-stores {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-app-store {
    margin-bottom: 1rem;
  }
}