.why-choose-us-container {
  padding-top: 2rem;
  padding-left: 3rem;
  padding-bottom: 5rem;
  background-color: #f8f8fb;
  font-family: 'Host Grotesk', sans-serif;
  position: relative;
  overflow: hidden;
}

.why-choose-us-content {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.why-choose-us-title {
  font-size: 3rem;
  font-weight: 600;
  color: #27195B;
  margin-bottom: 3rem;
}

.benefits-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 1rem;
}

.benefit-item {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.check-icon {
  margin-top: 0.2rem;
  width: 2rem;
  height: 2rem;
}

.benefit-content {
  display: flex;
  flex-direction: column;
}

.benefit-title {
  font-size: 2rem;
  font-weight: 500;
  color: #583095;
  margin: 0 0 0.5rem 0;
}

.benefit-description {
  font-size: 1.1rem;
  color: #27195B;
  margin: 0;
}

.illustration-container {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: end;
}

.delivery-illustration {
  width: 100%;
  max-width: 700px;
  height: auto;
  position: relative;
  z-index: 1;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .why-choose-us-container {
    flex-direction: column;
    padding: 2rem;
  }

  .why-choose-us-content {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .illustration-container {
    position: relative;
    width: 100%;
    height: 300px;
    margin-top: 2rem;
  }

  .why-choose-us-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .illustration-container::before {
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
  }
}

@media (max-width: 768px) {
  .benefit-title {
    font-size: 1.3rem;
  }

  .benefit-description {
    font-size: 1rem;
  }

  .why-choose-us-title {
    font-size: 2rem;
  }

  .check-icon {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .illustration-container {
    position: relative;
    width: 100%;
    height: 200px;
    margin-top: 2rem;
  }
}