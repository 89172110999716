/* Hero container */
.hero-container {
  width: 100%;
}

/* Hero background */
.hero-background {
  width: 100%;
  background-color: #f5f5f5;
  min-height: 100vh;
  position: relative;
  background-image: url('../../../assets/images/hero-background.jpg');
  background-size: cover;
  background-position: center;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem 1rem;
  position: relative;
}

/* Logo styles */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-image {
  height: 2.5rem;
  width: auto;
}

/* Mobile menu button */
.mobile-menu-button {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.menu-icon {
  width: 100%;
  height: 3px;
  background-color: #F9F4F2;
  border-radius: 10px;
  transition: all 0.3s linear;
}

/* Navigation styles */
.nav-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 5rem;
}

.nav-button {
  background: none;
  border: none;
  color: #F9F4F2;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.5rem;
  transition: color 0.3s ease;
  font-family: 'Host Grotesk', sans-serif;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  padding: 2rem 3rem 3rem;
  min-height: calc(80vh - 150px);
}

/* Hero text section */
.hero-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Hero title */
.hero-title {
  font-size: 5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #F9F4F2;
  font-family: 'Host Grotesk', sans-serif;
  line-height: 1.2;
}

/* Hero subtitle */
.hero-subtitle {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
  color: #F9F4F2;
  font-family: 'Host Grotesk', sans-serif;
  line-height: 1.6;
}

.app-ribbon-container {
  display: flex;
  width: 100%;
}

.app-ribbon {
  background: linear-gradient(90deg, rgba(221,85,85,1) 0%, rgba(147,107,207,1) 100%);
  border-radius: 30px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  color: white;
  
  width: fit-content;
  max-width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar in Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app-ribbon::-webkit-scrollbar {
  display: none;
}

.ribbon-text {
  font-size: 1.1rem;
  font-weight: 400;
  margin-right: 12px;
  font-family: 'Host Grotesk', sans-serif;
}

.app-stores {
  display: flex;
  align-items: center;
}

.app-store {
  display: flex;
  align-items: center;
  margin-right: 18px;
}

.app-store:last-child {
  margin-right: 0;
}

.apple-icon, .google-play-icon {
  margin-right: 8px;
  width: 28px;
  height: 28px;
}

.store-name {
  font-size: 1.05rem;
  font-weight: 400;
  font-family: 'Host Grotesk', sans-serif;
}

/* Hero image container */
.hero-image-container {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.15));
  transition: transform 0.3s ease;
}

.welcome-text {
  font-size: 1.4rem;
  font-weight: 500;
  color: #F9F4F2;
  font-family: 'Host Grotesk', sans-serif;
  margin-bottom: 2rem;
}

.promo-code {
  color: #FF8C38; /* Orange color for the promo code */
  font-weight: 700;
}

/* Responsive media queries */
@media screen and (max-width: 1280px) {
  .hero-title {
    font-size: 4rem;
  }
  
  .hero-subtitle {
    font-size: 1.6rem;
  }
  
  .nav-item {
    margin-left: 3rem;
  }

  .hero-image {
    max-width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .hero-text {
    width: 50%;
  }
  
  .hero-title {
    font-size: 3.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.4rem;
  }

  .hero-image-container {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 1.5rem 2rem;
  }
  
  .mobile-menu-button {
    display: flex;
  }

  .welcome-text {
    font-size: 1.2rem;
  }
  
  .nav {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 6rem 2rem 2rem;
    transition: right 0.3s ease;
    z-index: 5;
  }
  
  .nav-open {
    right: 0;
  }
  
  .nav-list {
    flex-direction: column;
    align-items: center;
  }
  
  .nav-item {
    margin: 1rem 0;
    margin-left: 0;
  }
  
  .hero-content {
    padding: 1rem 2rem;
    flex-direction: column;
    align-items: center;
  }
  
  .hero-text {
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .hero-title {
    font-size: 3rem;
  }
  
  /* Ribbon stays in one line */
  .app-ribbon {
    white-space: nowrap;
    overflow-x: auto;
    width: auto;
    max-width: 100%;
    padding: 12px 20px;
  }

  .hero-image-container {
    width: 80%;
    /* No order property - natural flow will place image below text */
  }
}

@media screen and (max-width: 480px) {
  .header {
    padding: 1rem 1.5rem;
  }

  .welcome-text {
    font-size: 1.1rem;
  }
  
  .hero-content {
    padding: 1rem 1.5rem;
  }
  
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
  }
  
  .hero-background {
    background-image: url('../../../assets/images/hero-background-mobile.jpg');
  }
  /* Ensure app stores stay in one row */
  .app-stores {
    display: flex;
    flex-direction: row;
  }
  
  .app-store {
    font-size: 0.9rem;
  }
  
  .ribbon-text {
    font-size: 0.9rem;
  }
  
  .store-name {
    font-size: 0.9rem;
  }
  
  .apple-icon, .google-play-icon {
    width: 20px;
    height: 20px;
  }
  
  .logo-image {
    height: 2rem;
  }

  .hero-image-container {
    width: 95%;
  }
}

/* Static banner */
.static-banner {
  background: linear-gradient(90deg, rgba(221,85,85,1) 0%, rgba(147,107,207,1) 100%);
  color: #fff;
  padding: 12px 0;
  width: 100%;
  text-align: center;
}

.banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.banner-text {
  font-family: 'Host Grotesk', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-text i {
  margin-right: 10px;
  font-size: 1.2rem;
}

.promo-code-banner {
  font-weight: 700;
  margin-left: 5px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .banner-text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .static-banner {
    padding: 10px 0;
  }
  
  .banner-text {
    font-size: 0.9rem;
  }
  
  .banner-text i {
    font-size: 1rem;
    margin-right: 6px;
  }
}